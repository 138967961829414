/** @jsx jsx */
import { Link } from 'gatsby';
import { jsx } from 'theme-ui';
import PropTypes from 'prop-types';

import KayzarLogo from '../../images/kayzar-logo.svg';

export const LOGO_HEIGHT = ['16px', null, '24px'];

const Logo = ({ siteTitle }) => {
  return (
    <Link
      to="/"
      sx={{
        color: 'text',
        fontFamily: 'heading',
        textDecoration: 'none',
      }}
    >
      <img
        sx={{
          color: 'text',
          fontFamily: 'heading',
          height: LOGO_HEIGHT,
          mb: 0,
          textDecoration: 'none',
        }}
        src={KayzarLogo}
        alt={siteTitle}
      />
    </Link>
  );
};

Logo.propTypes = {
  siteTitle: PropTypes.string,
};

export default Logo;
