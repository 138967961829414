/** @jsx jsx */
import { useStaticQuery, graphql } from 'gatsby';
import { jsx } from 'theme-ui';

import Logo from '../logo';
import { Wrapper, SiteHeading } from './components';

export const HEADER_HEIGHT = {
  xs: '48px',
  sm: '56px',
  lg: '88px',
};

const Header = () => {
  const data = useStaticQuery(graphql`
    query SiteTitleQuery {
      site {
        siteMetadata {
          title
        }
      }
    }
  `);
  const siteTitle = data.site.siteMetadata.title;

  return (
    <Wrapper>
      <SiteHeading>
        <Logo {...{ siteTitle }} />
      </SiteHeading>
    </Wrapper>
  );
};

export default Header;
