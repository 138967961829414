/* eslint-disable react/prop-types */
/* eslint-disable react/display-name */
/**
 * Implement Gatsby's Browser APIs in this file.
 *
 * See: https://www.gatsbyjs.org/docs/browser-apis/
 */

const React = require('react');
const { FiltersProvider } = require('./src/context/filters-context');
const { MenuProvider } = require('./src/context/menu-context');
const Layout = require('./src/components/layout').default;

exports.wrapRootElement = ({ element }) => {
  return (
    <MenuProvider>
      <FiltersProvider>{element}</FiltersProvider>
    </MenuProvider>
  );
};

exports.shouldUpdateScroll = ({ routerProps: { location } }) => {
  const scrollToStartPaths = [`/`];
  if (scrollToStartPaths.indexOf(location.pathname) !== -1) {
    const featuredImagesContainer = document.getElementById('featured-images');
    // Scroll to start (left) of Featured Images container when on horizontal viewport
    featuredImagesContainer.scrollLeft = 0;
    // Scroll to the top when on vertical viewport
    window.scrollTo(0, 0);
  }

  return false;
};

exports.wrapPageElement = ({ element, props }) => {
  return <Layout {...props}>{element}</Layout>;
};
