/** @jsx jsx */
import { Box, jsx } from 'theme-ui';
import ContactDetails from '../contact-details';
import { Container, Item } from '../grid';

export const FOOTER_HEIGHT = '50px';

const Footer = () => (
  <footer
    sx={{
      fontSize: 12,
      position: [null, null, null, 'fixed'],
      bottom: 0,
      left: 0,
      mt: 'auto',
      backgroundColor: '#333',
      height: FOOTER_HEIGHT,
      width: '100%',
      zIndex: 1,
    }}
  >
    {/* 👇 Match grid container styles with Layout Wrapper. */}
    <Container sx={{ margin: `0 auto`, px: [null, null, null, 4], py: 3 }}>
      <Item
        sx={{
          display: 'flex',
          flexDirection: 'column',
          '@media (orientation: landscape)': { flexDirection: 'row' },
        }}
      >
        <ContactDetails />
        <Box>
          © Kayzar {new Date().getFullYear()},{` `}
          All Right Reserved.
        </Box>
      </Item>
    </Container>
  </footer>
);

export default Footer;
