export default {
  colors: {
    highlight: '#FFF',
    text: '#A8A8A8',
    background: '#333',
    primary: '#F7941D',
  },
  fonts: {
    body: 'Roboto, system-ui, sans-serif',
    heading: 'Roboto,system-ui, sans-serif',
    monospace: 'Menlo, monospace',
  },
  fontWeights: {
    body: 400,
    heading: 700,
    bold: 700,
  },
  lineHeights: {
    body: 1.5,
    heading: 1.25,
    list: 1,
  },
  links: {
    color: 'text',
    cursor: 'pointer',
    transition: '0.1s ease-in all',
    ':hover': {
      color: 'highlight',
    },
    ':active': {
      color: 'highlight',
    },
  },
  fontSizes: [12, 13, 14, 16, 20, 24, 32, 48, 64, 72],
  space: [0, 4, 8, 16, 32, 64, 96, 128, 256, 512],
  breakpoints: ['576px', '768px', '960px', '1440px'],
};
