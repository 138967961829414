/** @jsx jsx */
import { Box, jsx } from 'theme-ui';
import PropTypes from 'prop-types';

const Wrapper = ({ children, ...props }) => (
  <Box
    {...props}
    sx={{
      fontFamily: 'body',
    }}
  >
    {children}
  </Box>
);

Wrapper.propTypes = {
  children: PropTypes.node,
};

const Main = ({ children, ...props }) => (
  <Box
    {...props}
    as="main"
    sx={{
      overflow: 'hidden',
      '@media (orientation: landscape)': {
        display: 'flex',
      },
    }}
  >
    {children}
  </Box>
);

Main.propTypes = {
  children: PropTypes.node,
};

export { Wrapper, Main };
