/** @jsx jsx */
import { jsx, Flex, Link } from 'theme-ui';
import PropTypes from 'prop-types';
import Icon from '../icon';

const EmailIcon = () => (
  <path d="M0 3v18h24v-18h-24zm6.623 7.929l-4.623 5.712v-9.458l4.623 3.746zm-4.141-5.929h19.035l-9.517 7.713-9.518-7.713zm5.694 7.188l3.824 3.099 3.83-3.104 5.612 6.817h-18.779l5.513-6.812zm9.208-1.264l4.616-3.741v9.348l-4.616-5.607z" />
);

const PhoneIcon = () => (
  <path d="M6.176 1.322l2.844-1.322 4.041 7.89-2.724 1.341c-.538 1.259 2.159 6.289 3.297 6.372.09-.058 2.671-1.328 2.671-1.328l4.11 7.932s-2.764 1.354-2.854 1.396c-7.862 3.591-19.103-18.258-11.385-22.281zm1.929 1.274l-1.023.504c-5.294 2.762 4.177 21.185 9.648 18.686l.971-.474-2.271-4.383-1.026.5c-3.163 1.547-8.262-8.219-5.055-9.938l1.007-.497-2.251-4.398z" />
);

const Wrapper = ({ children, ...props }) => (
  <Flex
    id="contact-details"
    sx={{ flexWrap: 'wrap', textAlign: ['center', null, 'left'], pt: [1, 0] }}
    {...props}
  >
    {children}
  </Flex>
);

Wrapper.propTypes = {
  children: PropTypes.node,
};

const ContactDetail = ({ children, method, ...props }) => (
  <Flex
    sx={{
      alignItems: 'center',
      mb: [1, null, null, 0],
      mr: 2,
      justifyContent: ['center', null, 'flex-start'],
    }}
    {...props}
  >
    <Icon
      fill="#A8A8A8"
      width="12"
      height="12"
      viewBox="0 0 24 24"
      sx={{ mr: 1, minWidth: '12px' }}
    >
      {method === 'email' ? (
        <EmailIcon />
      ) : method === 'phone' ? (
        <PhoneIcon />
      ) : null}
    </Icon>
    <Link
      href={
        method === 'email'
          ? `mailto:${children}`
          : method === 'phone'
          ? `tel:${children}`
          : null
      }
      sx={{
        display: 'block',
        color: 'text',
        textDecoration: 'none',
        fontSize: [0, 1, null],
      }}
    >
      {children}
    </Link>
  </Flex>
);

ContactDetail.propTypes = {
  children: PropTypes.node,
  method: PropTypes.oneOf(['email', 'phone']),
};

export { ContactDetail, Wrapper };
