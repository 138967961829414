import React, { useReducer, useContext } from 'react';
import PropTypes from 'prop-types';
import { initialState, filtersReducer } from './filters-reducer';

const FiltersContext = React.createContext();

const FiltersProvider = ({ children }) => {
  const [state, dispatch] = useReducer(filtersReducer, initialState);

  return (
    <FiltersContext.Provider value={{ state, dispatch }}>
      {children}
    </FiltersContext.Provider>
  );
};

const useFilters = () => useContext(FiltersContext);

FiltersProvider.propTypes = {
  children: PropTypes.node,
};

export { FiltersProvider, useFilters };
