import { union, without, size } from 'lodash';

const initialState = { filters: [] };

const filtersReducer = (state, action) => {
  switch (action.type) {
    case 'ADD_FILTER': {
      return {
        ...state,
        filters: union(state.filters, [action.value]),
      };
    }

    case 'REMOVE_FILTER': {
      return {
        ...state,
        filters: without(state.filters, action.value),
      };
    }

    case 'TOGGLE_ALL_FILTERS':
      {
        if (size(state.filters) >= 0) {
          return initialState;
        }
      }
      break;
    default:
      throw new Error('Unknown action type for filtersReducer.');
  }
};

export { initialState, filtersReducer };
