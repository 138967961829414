/** @jsx jsx */
import { Box, jsx } from 'theme-ui';
import PropTypes from 'prop-types';

import { Item } from '../grid';

const Wrapper = ({ children, ...props }) => {
  return (
    <Item
      {...props}
      as="header"
      sx={{
        display: 'flex',
        backgroundColor: `#333`,
        fontFamily: 'heading',
        flexDirection: 'column',
        pl: [2, null, null, 4],
        py: [3, null, null, 4],
        position: 'fixed',
        top: 0,
        zIndex: 1,
        width: `100%`,
        '@media (orientation: landscape)': {
          flexDirection: 'row',
        },
      }}
    >
      {children}
    </Item>
  );
};

Wrapper.propTypes = {
  children: PropTypes.node,
};

const SiteHeading = ({ children, ...props }) => (
  <Box
    {...props}
    as="h1"
    sx={{
      lineHeight: 0,
      maxWidth: '250px',
      mb: 0,
    }}
  >
    {children}
  </Box>
);

SiteHeading.propTypes = {
  children: PropTypes.node,
  page: PropTypes.string,
};

export { Wrapper, SiteHeading };
