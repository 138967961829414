/** @jsx jsx */
import { Flex, Box, jsx } from 'theme-ui';
import PropTypes from 'prop-types';

const Container = ({ children, ...props }) => (
  <Flex {...props} sx={{ mx: -2 }}>
    {children}
  </Flex>
);

Container.propTypes = {
  children: PropTypes.node.isRequired,
};

const Item = ({ children, ...props }) => (
  <Box {...props} sx={{ px: 2 }}>
    {children}
  </Box>
);

Item.propTypes = {
  children: PropTypes.node.isRequired,
};

export { Container, Item };
