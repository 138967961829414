import PropTypes from 'prop-types';
import React from 'react';

const Icon = ({
  children,
  fill,
  height,
  transform,
  viewBox,
  width,
  ...props
}) => (
  <svg
    fill={fill}
    height={height}
    transform={transform}
    version="1.1"
    viewBox={viewBox}
    width={width}
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    {children}
  </svg>
);

Icon.propTypes = {
  fill: PropTypes.string,
  height: PropTypes.string,
  viewBox: PropTypes.string,
  width: PropTypes.string,
  transform: PropTypes.string,
  children: PropTypes.node.isRequired,
};

Icon.defaultProps = {
  fill: null,
  height: '24',
  viewBox: '0 0 24 24',
  width: '24',
  transform: '0deg',
};

export default Icon;
