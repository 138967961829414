import React, { useContext, useState } from 'react';
import PropTypes from 'prop-types';

const MenuContext = React.createContext();

const MenuProvider = ({ children }) => {
  const [isMenuOpen, setIsMenuOpen] = useState(false);

  return (
    <MenuContext.Provider value={{ isMenuOpen, setIsMenuOpen }}>
      {children}
    </MenuContext.Provider>
  );
};

const useMenu = () => useContext(MenuContext);

MenuProvider.propTypes = {
  children: PropTypes.node,
};

export { MenuProvider, useMenu };
