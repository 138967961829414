/** @jsx jsx */
import { jsx } from 'theme-ui';
import { ContactDetail, Wrapper } from './components';
import { EMAIL, PHONE } from '../../constants/contact-details';

const ContactDetails = () => (
  <Wrapper>
    <ContactDetail method="email">{EMAIL}</ContactDetail>
    <ContactDetail method="phone">{PHONE}</ContactDetail>
  </Wrapper>
);

export default ContactDetails;
