import PropTypes from 'prop-types';
import React from 'react';

import Header from '../header';
import Footer from '../footer';

import { Wrapper, Main } from './components';
import './layout.css';

export const SCROLLBAR_WIDTH = '1rem';

const Layout = ({ children }) => {
  return (
    <Wrapper>
      <Header />
      <Main>{children}</Main>
      <Footer />
    </Wrapper>
  );
};

Layout.propTypes = {
  children: PropTypes.node.isRequired,
};

export default Layout;
